.Pagination-module__root--1EncZ {
  display: block;
  margin-top: var(--pico);
  text-align: center;
}

.Pagination-module__page--2h7Pi {
  background: transparent;
  border-width: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.Pagination-module__page--2h7Pi:hover {
  text-decoration: underline;
}

.Pagination-module__page--2h7Pi + .Pagination-module__page--2h7Pi {
  margin-left: var(--pico);
}

.Pagination-module__selectedPage--3WQPg,
.Pagination-module__ellipsis--3O6Wf {
  color: var(--text);
  cursor: auto;
  pointer-events: none;
}

.Pagination-module__root--1EncZ > .Pagination-module__page--2h7Pi,
.Pagination-module__page--2h7Pi + .Pagination-module__page--2h7Pi {
  text-decoration: none;
}

.Pagination-module__root--1EncZ > .Pagination-module__selectedPage--3WQPg,
.Pagination-module__page--2h7Pi + .Pagination-module__selectedPage--3WQPg {
  color: var(--text);
}

.Pagination-module__ellipsis--3O6Wf + .Pagination-module__ellipsis--3O6Wf {
  display: none;
}

@media screen and (min-width: 900px) {
  .Pagination-module__root--1EncZ {
    text-align: right;
  }
}

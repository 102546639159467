/* Sectioning */
main header,
caption,
dl {
  box-shadow: none;
  margin-bottom: var(--milli);
}

article section,
form section {
  border-bottom: var(--border);
  margin-bottom: var(--milli);
}

article section:last-child,
form section:last-child {
  border-bottom: 0;
}

/* Lists */
dt {
  color: var(--text-hint);
  font-size: var(--body-small-size);
  line-height: var(--body-large-line);
}

dd {
  margin-bottom: var(--milli);
}

small dd {
  margin: 0;
}

.Container-module__container--1e9BV {
  box-sizing: border-box;
  max-width: var(--max-page-width);
  width: 100%;
}

.Container-module__pageContainer--ht1Kv {
  margin: auto;
  padding: 0 var(--micro);
}

@media screen and (min-width: 900px) {
  .Container-module__container--1e9BV {
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--micro);
  }

  .Container-module__pageContainer--ht1Kv {
    margin-bottom: 40px;
  }
}

.TaskList-module__taskList--1JA2S {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  margin-bottom: 24px;
  margin-top: 10px;
}

.TaskList-module__taskTitle--1IC1S {
  font-size: 20px;
  color: #333;
  margin-bottom: 16px;
}

/* Basic reset */
html,
body {
  height: 100%;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dd,
ul {
  margin: 0;
  padding: 0;
}

header ul {
  list-style-type: none;
}

/* Headings */
h4 {
  font-size: var(--heading4-size);
  font-weight: var(--font-weight-highlight);
  letter-spacing: 0.33px;
  line-height: var(--heading4-line);
  margin-top: var(--milli);
}

h4 + p,
h5 {
  font-size: var(--subtitle-size);
  font-weight: var(--font-weight);
  letter-spacing: 0.28px;
  line-height: var(--subtitle-line);
}

h4 + p {
  margin-top: var(--atto);
}

h5 {
  margin-top: var(--milli);
}

h5 + p {
  margin-top: var(--atto);
}

small {
  display: block;
}

/* Primary button */
a[data-type='primary'],
button[type='submit'],
button[type='submit']:hover {
  text-decoration: none;
  background: var(--bg-button-primary);
  color: var(--text-button-primary);
  outline: none;
}

a[data-type='primary']:focus,
button[type='submit']:focus {
  background: var(--gradient-primary);
  box-shadow: 0 0 0 4px var(--pure-white), 0 0 0 5px #717171,
    0 0 0 6px rgba(255, 255, 255, 0.5);
  color: var(--text-button-primary);
}

a[data-type='primary']:active,
button[type='submit']:active {
  background: var(--gradient-primary-active);
  box-shadow: 0 0 0 4px var(--pure-white), 0 0 0 5px #717171,
    0 0 0 6px rgba(255, 255, 255, 0.5);
  color: var(--text-button-primary);
}

button[type='submit']:disabled {
  background: var(--bg-button-primary-disabled);
  color: var(--text-button-primary);
  opacity: 0.4;
}

/* Secondary button */
button[type='button'],
button[type='reset'],
button[type='button']:hover,
button[type='reset']:hover {
  background: var(--bg-button-secondary);
  border: var(--zepto) solid var(--text-button-secondary);
  color: var(--text-button-secondary);
  outline: none;
}

button[type='button']:focus,
button[type='reset']:focus {
  background: var(--bg-button-secondary);
  box-shadow: 0 0 0 var(--atto) var(--pure-white), 0 0 0 5px #717171;
}

button[type='button']:active,
button[type='reset']:active {
  color: var(--text-button-secondary-active);
  border: var(--zepto) solid var(--text-button-secondary-active);
}

button[type='button']:disabled,
button[type='reset']:disabled {
  background: var(--bg-button-secondary);
  color: var(--text-button-secondary);
  outline: none;
  mix-blend-mode: normal;
  opacity: 0.4;
}

/* Actions, buttons, links */
a {
  color: var(--text-link);
  text-decoration: underline;
  vertical-align: top;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

a[data-type='primary'],
a[data-type='tertiary'],
button {
  border: 0;
  border-radius: var(--atto);
  cursor: pointer;
  font-family: var(--font-demi);
  font-size: var(--body-small-line);
  font-weight: var(--font-weight-highlight);
  letter-spacing: var(--letter-spacing-small);
  line-height: var(--body-line);
  padding: var(--pico) var(--milli);
  width: 100%;
}

@media (min-width: 768px) {
  a[data-type='primary'],
  a[data-type='tertiary'],
  button {
    max-width: 33vw;
    padding: var(--pico) var(--hecto);
    width: auto;
  }
}

/* Tertiary button */
a[data-type='tertiary'],
button[data-type='tertiary'] {
  background-color: var(--bg-button-tertiary);
  border: var(--yocto) solid transparent;
  color: var(--text-button-tertiary);
  font-weight: var(--font-weight-highlight);
  font-size: var(--body-size);
  letter-spacing: var(--letter-spacing-small);
  line-height: var(--body-line);
  padding: 0 var(--atto);
  text-decoration: underline;
  width: auto;
}

a[data-type='tertiary']:focus,
a[data-type='tertiary']:hover,
button[data-type='tertiary']:focus,
button[data-type='tertiary']:hover {
  background-color: var(--bg-button-tertiary-hover);
  border: var(--yocto) solid var(--color-black);
  border-radius: var(--atto);
  box-shadow: none;
  color: var(--text-button-tertiary-hover);
  text-decoration: none;
}

a[data-type='tertiary']:active,
button[data-type='tertiary']:active {
  background-color: var(--bg-button-tertiary-hover);
  color: var(--text-button-tertiary-active);
}

a[data-type='tertiary']:disabled,
button[data-type='tertiary']:disabled {
  color: var(--text-button-tertiary);
  outline: none;
  mix-blend-mode: normal;
  opacity: 0.4;
}

/* Form */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input[disabled],
select[disabled],
textarea[disabled] {
  background: var(--bg-disabled-input);
  color: var(--text-hint);
  cursor: not-allowed;
}

button[disabled],
button[disabled]:hover {
  box-shadow: unset;
  cursor: not-allowed;
  outline-color: unset;
  outline-offset: 0;
}

input[type='checkbox'] {
  height: var(--micro);
  margin: 0 var(--nano) 0 0;
  opacity: 0;
  position: absolute;
  vertical-align: middle;
  width: var(--micro);
  z-index: -1;
}

input[type='checkbox'] + span {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20width%3D%2718%27%20height%3D%2718%27%20viewBox%3D%270%200%2018%2018%27%3E%3Crect%20width%3D%2717%27%20height%3D%2717%27%20x%3D%27.5%27%20y%3D%27230.5%27%20fill%3D%27none%27%20fill-rule%3D%27nonzero%27%20stroke%3D%27%23FF4C00%27%20stroke-opacity%3D%27.72%27%20rx%3D%272%27%20transform%3D%27translate%280%20-230%29%27/%3E%3C/svg%3E');
  background-position: 0 var(--zepto);
  background-repeat: no-repeat;
  display: inline-block;
  padding-left: calc(var(--micro) + var(--femto));
}

input[type='checkbox']:checked + span {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20xmlns%3Axlink%3D%27http%3A//www.w3.org/1999/xlink%27%20width%3D%2718%27%20height%3D%2718%27%20viewBox%3D%270%200%2018%2018%27%3E%3Cdefs%3E%3Cpath%20id%3D%27a%27%20d%3D%27M4%208.5L2.5%2010l4%204%209-8.5L14%204l-7.5%207z%27/%3E%3C/defs%3E%3Cg%20fill%3D%27none%27%20fill-rule%3D%27evenodd%27%3E%3Crect%20width%3D%2718%27%20height%3D%2718%27%20fill%3D%27%23FF4C00%27%20fill-rule%3D%27nonzero%27%20rx%3D%272%27/%3E%3Cuse%20fill%3D%27%23FFF%27%20xlink%3Ahref%3D%27%23a%27/%3E%3C/g%3E%3C/svg%3E');
}

/* https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 100vh var(--pure-white) inset;
}

label {
  background-color: var(--pure-white);
  font-size: var(--label-size);
  letter-spacing: var(--yocto);
  line-height: var(--label-line);
  margin: var(--zepto) 0 0 var(--femto);
  text-transform: uppercase;
}

form small {
  color: var(--text-hint);
  font-size: var(--body-small-size);
  font-weight: var(--font-weight-highlight);
  line-height: var(--body-small-line);
  padding-top: var(--femto);
}

/* Classes */
.global__overline--1Ui0p {
  font-size: 10px;
  font-weight: var(--regular);
  letter-spacing: 0.8px;
  line-height: 20px;
  text-transform: uppercase;
}

.global__body-small--ND8cR {
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 20px;
}

.global__body-large--1yHzL {
  font-size: 18px;
  letter-spacing: 0.25px;
  line-height: 28px;
}

.global__heading3--ncJ-S {
  font-size: 24px;
  font-weight: var(--semiBold);
  letter-spacing: 0.33px;
  line-height: 32px;
}

.global__heading2--130Cw {
  font-size: 44px;
  font-weight: var(--semiBold);
  letter-spacing: 0.61px;
  line-height: 56px;
}

.global__heading1--10Tzh {
  font-size: 56px;
  font-weight: var(--semiBold);
  letter-spacing: 0.78px;
  line-height: 68px;
}

.global__display--2INOk {
  font-size: 64px;
  font-weight: var(--semiBold);
  letter-spacing: 0.89px;
  line-height: 72px;
}

.global__list--2EAKj {
  margin: 0;
  padding-left: 0;
}

.global__listItem--1Dt92 {
  list-style-type: none;
}

.global__link--Ga_-y {
  color: var(--light-black);
  display: inline-block;
  padding: var(--micro);
  text-decoration: none;
}

.global__screenReaderOnly--2piHn {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.global__linkAsButton--2ZtBA {
  background: var(--bg-button-primary);
  border: 0;
  border-radius: var(--atto);
  color: var(--text-button-primary);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-demi);
  font-size: var(--body-large-size);
  font-weight: var(--font-weight-highlight);
  letter-spacing: var(--letter-spacing-small);
  line-height: var(--body-line);
  padding: var(--pico) var(--milli);
  text-decoration: none;
}

.global__linkAsButton--2ZtBA:focus {
  background: var(--gradient-primary);
  box-shadow: 0 0 0 4px var(--pure-white), 0 0 0 5px #717171,
    0 0 0 6px rgba(255, 255, 255, 0.5);
  color: var(--text-button-primary);
  outline: none;
}

/* Footer */
footer {
  background: var(--color-black);
  color: var(--text-footer);
  font-weight: var(--font-weight-highlight);
  padding: var(--deci) 0;
}

footer a {
  color: var(--text-footer-link);
  font-weight: var(--font-weight);
}

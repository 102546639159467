.Layout-module__root--3RNqu {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.Layout-module__form--1UjW6 {
  width: 100%;
}

.Layout-module__main--XRaz7 {
  flex: 1;
  position: relative;
}

button {
  margin-bottom: var(--pico);
}

@media screen and (min-width: 1024px) {
  button + button {
    margin-left: var(--milli);
  }
  .Layout-module__form--1UjW6 {
    width: 50%;
  }
  .Layout-module__form--1UjW6:nth-of-type(even) {
    padding-left: var(--pico);
  }
}

.PropertyWithTitle-module__root--fuK6S {
}

.PropertyWithTitle-module__label--1GLPv {
  color: var(--text-hint);
  font-size: var(--body-small-size);
}

.PropertyWithTitle-module__labelWithError--3R20W {
  color: var(--color-error);
}

.Footer-module__root--11CHY {
  padding: 0 var(--micro);
}
.Footer-module__linksWrapper--yv01- {
  margin: var(--nano) 0 0 0;
}
.Footer-module__links--3MFss {
  display: block;
  font: var(--font-weight-highlight);
  margin: var(--femto) 0 0 0;
}

@media screen and (min-width: 1024px) {
  .Footer-module__root--11CHY {
    align-items: center;
    display: flex;
  }
  .Footer-module__copyright--17L1Y {
    white-space: nowrap;
  }
  .Footer-module__linksWrapper--yv01- {
    margin: 0;
  }
  .Footer-module__links--3MFss {
    display: inline-block;
    margin: 0 var(--micro) 0 0;
  }
  .Footer-module__links--3MFss:first-child {
    margin-left: var(--deka);
  }
  .Footer-module__links--3MFss:last-child {
    margin-right: 0;
  }
}

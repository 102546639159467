@media screen and (min-width: 900px) {
  .LspForm-module__row--1dClT {
    display: flex;
    gap: 10px;
  }

  .LspForm-module__w150--38Co4 {
    width: 150px;
    flex-shrink: 0;
  }
}

.LspForm-module__section--1OaNQ {
  margin-bottom: 20px;
}

.DetailsLink-module__root--2QOZz {
  color: var(--orange);
  display: block;
  margin-top: var(--micro);
  text-decoration: none;
}

.DetailsLink-module__inlined--29Q9D {
  display: inline-block;
}

.DetailsLink-module__inlined--29Q9D:nth-child(2) {
  padding-left: var(--pico);
}

.DetailsLink-module__edit--3fujO {
}

@media screen and (min-width: 900px) {
  .DetailsLink-module__root--2QOZz {
    margin-top: 0;
  }
  .DetailsLink-module__edit--3fujO {
    margin-top: var(--centi);
    position: absolute;
    right: var(--milli);
  }
}

.ChauffeurForm-module__section--37L3k {
  margin-bottom: 20px;
}

@media screen and (min-width: 900px) {
  .ChauffeurForm-module__row--2_7Iy {
    display: flex;
    gap: 10px;
  }

  .ChauffeurForm-module__w150--wc3B8 {
    width: 150px;
    flex-shrink: 0;
  }
}

.ChauffeurForm-module__placeholder--3Vuip {
  width: 100%;
}

@media screen and (min-width: 900px) {
  .VehicleForm-module__row--1ojTX {
    display: flex;
    gap: 10px;
  }
}

.VehicleForm-module__section--1fdWe {
  margin-bottom: 20px;
}

.EmptyList-module__root--l2-oA {
}

.EmptyList-module__image--1nYwo {
  display: block;
  margin: var(--micro) auto;
}

.EmptyList-module__text--ob3Iy {
  display: block;
  text-align: center;
}

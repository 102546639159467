/* dayPicker Story variables */

.datePicker__DayPicker--3pS8V {
  display: inline-block;
  font-size: 1rem;
  width: 100%;
}

.datePicker__DayPicker-wrapper--3zHi_ {
  flex-direction: row;
  padding: var(--nano);
  position: relative;
  user-select: none;
  background-color: var(--pure-white);
}

.datePicker__DayPicker-wrapper--3zHi_:focus {
  border-radius: var(--atto);
  border: 2.5px solid var(--color-black);
  outline: none;
}

.datePicker__DayPicker-Months--3imDy {
  background-color: var(--pure-white);
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: center;
}

.datePicker__DayPicker-Month--yQWJ2 {
  border-collapse: collapse;
  margin: 0 1em;
  margin: 0;
  margin-top: 1em;
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.datePicker__DayPicker-NavButton--1IW_w {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  color: #8b9898;
  cursor: pointer;
  display: inline-block;
  height: 1.25em;
  left: auto;
  margin-top: var(--zepto);
  position: absolute;
  right: 1.5em;
  top: 1em;
  width: 1.25em;
}

.datePicker__DayPicker-NavButton--1IW_w:hover {
  opacity: 0.8;
}

.datePicker__DayPicker-NavButton--prev--9oJfv {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  margin-right: 1.5em;
}

.datePicker__DayPicker-NavButton--next--2PeZ- {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.datePicker__DayPicker-NavButton--interactionDisabled--2REn8 {
  display: none;
}

.datePicker__DayPicker-Caption--1V-kk {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.datePicker__DayPicker-Caption--1V-kk > div {
  font-size: 1.15em;
  font-weight: 500;
}

.datePicker__DayPicker-Weekdays--2aDf1 {
  margin-top: 1em;
  display: flex;
}

.datePicker__DayPicker-WeekdaysRow--2zEP7 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.datePicker__DayPicker-Weekday--3qVeq {
  color: var(--color-input-text);
  font-size: 0.875em;
  padding: 0.5em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datePicker__DayPicker-Weekday--3qVeq abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.datePicker__DayPicker-Body--1xDw9 {
  display: flex;
  flex-direction: column;
}

.datePicker__DayPicker-Week--StJUl {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--femto);
}

.datePicker__DayPicker-Day--1xRvC {
  align-items: center;
  border-radius: var(--atto);
  color: var(--main-black);
  cursor: pointer;
  display: flex;
  height: var(--hecto);
  justify-content: center;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.datePicker__DayPicker-Day--1xRvC {
  font-size: var(--body-size);
  line-height: var(--body-line);
}

.datePicker__DayPicker-WeekNumber--WaXF- {
  border-right: var(--yocto) solid #eaecec;
  color: #8b9898;
  cursor: pointer;
  display: table-cell;
  font-size: 0.75em;
  min-width: 1em;
  padding: 0.5em;
  text-align: right;
  vertical-align: middle;
}

.datePicker__DayPicker--interactionDisabled--1IbLY .datePicker__DayPicker-Day--1xRvC {
  cursor: default;
}

.datePicker__DayPicker-Footer--2LroV {
  padding-top: 0.5em;
}

.datePicker__DayPicker-TodayButton--2_YRZ {
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  color: #4a90e2;
  cursor: pointer;
  font-size: 0.875em;
}

/* Default modifiers */

.datePicker__DayPicker-Day--today--lUNfQ p {
  border-radius: 100%;
  border: var(--yocto) solid var(--main-black);
  color: var(--text);
  font-weight: var(--font-weight);
  height: 100%;
  padding: var(--pico) 0;
  box-sizing: border-box;
  width: var(--hecto);
}

.datePicker__DayPicker-Day--outside--1ITZz {
  background-color: var(--pure-white) !important;
  color: #8b9898;
  cursor: default;
}

.datePicker__DayPicker-Day--disabled--1IpXA {
  color: var(--cool-grey);
  cursor: default;
}

.datePicker__DayPicker-Day--disabled--1IpXA:hover {
  background-color: transparent !important;
}

/* Example modifiers */

.datePicker__DayPicker-Day--sunday--3BS0E {
  background-color: #f7f8f8;
}

.datePicker__DayPicker-Day--sunday--3BS0E:not(.datePicker__DayPicker-Day--today--lUNfQ) {
  color: #dce0e0;
}

.datePicker__DayPicker-Day--selected--25cEB.datePicker__DayPicker-Day--start--3aJtM:not(.datePicker__DayPicker-Day--end--2-74d):not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):before,
.datePicker__DayPicker-Day--selected--25cEB.datePicker__DayPicker-Day--end--2-74d:not(.datePicker__DayPicker-Day--start--3aJtM):not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):before {
  background-color: var(--color-input-background);
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 0;
}

.datePicker__DayPicker-Day--selected--25cEB.datePicker__DayPicker-Day--end--2-74d:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):before {
  left: 0;
}

.datePicker__DayPicker-Day--selected--25cEB.datePicker__DayPicker-Day--start--3aJtM:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz),
.datePicker__DayPicker-Day--selected--25cEB.datePicker__DayPicker-Day--end--2-74d:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz) {
  background-color: transparent;
}

.datePicker__calendar--2eYo3
  .datePicker__DayPicker-Day--selected--25cEB:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz)
  p,
.datePicker__DayPicker-Day--selected--25cEB.datePicker__DayPicker-Day--start--3aJtM:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz)
  p,
.datePicker__DayPicker-Day--selected--25cEB.datePicker__DayPicker-Day--end--2-74d:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz)
  p,
.datePicker__DayPicker-Day--1xRvC:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):hover
  p {
  background-color: var(--main-black);
  border-radius: 100%;
  border-width: 0;
  box-sizing: border-box;
  color: var(--pure-white);
  font-weight: var(--font-weight-highlight);
  height: 100%;
  padding: var(--pico) 0;
  position: relative;
  width: var(--hecto);
  z-index: 1;
}

.datePicker__DayPicker-Day--1xRvC:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):active {
  z-index: 5;
}

.datePicker__calendar--2eYo3
  .datePicker__DayPicker-Day--selected--25cEB:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz) {
  background-color: transparent;
}

.datePicker__DayPicker-Day--selected--25cEB:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz) {
  background-color: var(--color-input-background);
  border-radius: 0;
  color: var(--main-black);
  position: relative;
}

.datePicker__DayPicker-Day--selected--25cEB:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz)
  p {
  font-weight: var(--font-weight-highlight);
}

.datePicker__DayPicker-Day--selected--25cEB:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):not(.datePicker__DayPicker-Day--start--3aJtM):first-child,
.datePicker__DayPicker-Day--selected--25cEB:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):not(.datePicker__DayPicker-Day--end--2-74d):first-child {
  border-radius: var(--atto) 0 0 var(--atto);
}

.datePicker__DayPicker-Day--selected--25cEB:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):not(.datePicker__DayPicker-Day--start--3aJtM):last-child,
.datePicker__DayPicker-Day--selected--25cEB:not(.datePicker__DayPicker-Day--disabled--1IpXA):not(.datePicker__DayPicker-Day--outside--1ITZz):not(.datePicker__DayPicker-Day--end--2-74d):last-child {
  border-radius: 0 var(--atto) var(--atto) 0;
}

/* DayPickerInput */

.datePicker__DayPickerInput--3_Fs2 {
  display: inline-block;
}

.datePicker__DayPickerInput-OverlayWrapper--1eklr {
  position: relative;
}

.datePicker__DayPickerInput-Overlay--169Rw {
  background: var(--pure-white);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  left: 0;
  position: absolute;
  z-index: 1;
}

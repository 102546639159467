.DetailPage-module__container--_HmoI {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    'header'
    'bookingNumber'
    'status'
    'details'
    'highlights';
  grid-template-columns: 1fr;
}
.DetailPage-module__header--35QLT {
  grid-area: header;
}
.DetailPage-module__bookingNumber--3qiZX {
  grid-area: bookingNumber;
}
.DetailPage-module__status--1lyLb {
  grid-area: status;
}
.DetailPage-module__emptyStatus--3IPM1 {
  display: none;
}
.DetailPage-module__details--1r25P {
  grid-area: details;
}
.DetailPage-module__highlights--47Mhp {
  grid-area: highlights;
}

.DetailPage-module__goToList--zydEz {
  display: none;
  margin-top: var(--normal);
}

.DetailPage-module__lastSection--3aWWK {
  border-bottom: 0;
}
.DetailPage-module__disabled--1zayB {
  opacity: 0.2;
}

.DetailPage-module__informationProperty--3S0Fq {
  margin-bottom: var(--atto);
}
.DetailPage-module__listItem--2ijfn {
  list-style-type: none;
  border-bottom: solid var(--border-size) var(--color-border);
  padding: var(--pico) 0;
}

.DetailPage-module__section--YP-Ru {
  padding-bottom: var(--milli);
}

@media screen and (min-width: 900px) {
  .DetailPage-module__container--_HmoI {
    grid-template-areas:
      'header header'
      'bookingNumber status'
      'details highlights';
    grid-template-columns: 3fr 1fr;
    grid-gap: 0 var(--nano);
  }
  .DetailPage-module__lastSection--3aWWK {
    margin-bottom: 0;
  }
  .DetailPage-module__emptyStatus--3IPM1 {
    border-bottom: 1px solid var(--color-border);
    display: unset;
  }
  .DetailPage-module__fieldContainer--1m0Bl {
    box-sizing: border-box;
    width: 50% !important; /* Needed due to how the CSS Module's composes + design system works */
  }
  .DetailPage-module__removeResource--jCBJG {
    display: block;
    margin: 0 0 0 auto;
  }
}

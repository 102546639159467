.Content-module__content--27OZD {
  margin: 0 auto;
  max-width: 600px;
}

.Content-module__centered--35GoB {
  align-items: center;
  text-align: center;
}

.Content-module__marginTop--13fnv {
  margin-top: 50px;
}

.HTTPError-module__root--1fVdd {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 50px;
  margin-top: 50px;
  border-radius: 8px;
}

.HTTPError-module__text--3j84F {
  margin-bottom: var(--normal);
}

@media screen and (min-width: 900px) {
  .HTTPError-module__root--1fVdd {
    align-items: center;
    text-align: center;
    padding: 200px;
    margin-top: 100px;
  }
}

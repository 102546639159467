:root {
  /* Font Colors */
  --text-error: var(--color-error);
  --text-link: var(--color-black);
  --text: var(--color-black);

  /* Border Colors */
  --color-border: var(--color-selected-item);
  --border-size: 1px;
  --border-color: var(--color-selected-item);
  --border: var(--border-size) solid var(--border-color);
  --border-focus: 1px solid var(--outline-button-secondary-hover);

  /* Icon Colors */
  --icon-stroke: var(--text-hint);

  /* Buttons */
  --bg-button-primary-disabled: var(--gradient-primary-disabled);
  --bg-button-primary: var(--gradient-primary);
  --bg-button-secondary: var(--pure-white);
  --bg-button-tertiary-hover: transparent;
  --bg-button-tertiary: transparent;
  --outline-button-secondary-hover: var(--orange);
  --text-button-primary: var(--pure-white);
  --text-button-secondary-active: var(--dark-orange);
  --text-button-secondary: var(--orange);
  --text-button-tertiary-active: var(--main-black);
  --text-button-tertiary-hover: var(--pure-black);
  --text-button-tertiary: var(--pure-black);

  /* Validation colors */
  --color-validated: var(--green);

  /* Container */
  --bg-container-start: #292929;
  --bg-container-end: #181a1f;
  --bg-container: linear-gradient(
    160deg,
    var(--bg-container-start),
    var(--bg-container-end)
  );
  --max-page-width: 1170px;
  --item-box-shadow: 0px var(--femto) var(--nano) #caccd1;

  /* Header */
  --bg-header-primary: var(--pure-white);
  --outline-header: 0 2px 10px 0 rgba(0, 0, 0, 0.05),
    0 -1px 10px 0 rgba(0, 0, 0, 0.01);

  /* Footer */
  --text-footer: var(--pure-white);
  --text-footer-link: var(--line-bottom);
}

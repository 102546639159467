.Table-module__table--19Z8B {
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 0;
  width: 100%;
}

.Table-module__tableHead--3vToU {
  display: none;
}

.Table-module__tableRow--3AB2t {
  border-bottom: solid var(--border-size) var(--color-border);
  display: block;
}

.Table-module__tableCell--3jQ2f {
  word-break: break-word;
}

.Table-module__itemTitle--foDhV {
  font-weight: var(--font-weight);
}

.Table-module__columnTitle--11-D9,
.Table-module__tableCell--3jQ2f {
  display: block;
  padding: var(--pico) 0;
}

.Table-module__columnTitle--11-D9 {
  color: var(--text-hint);
  font-size: var(--body-small-size);
  font-weight: var(--font-weight);
  text-align: left;
}

.Table-module__columnTitle--11-D9:last-child,
.Table-module__tableCell--3jQ2f:last-child {
  padding-right: 0;
  text-align: right;
}

.Table-module__listHead--3Oik_ {
  border-bottom: solid var(--border-size) var(--color-border);
}

.Table-module__loadingMessage---9y2- {
  margin-top: var(--pico);
}

@media screen and (min-width: 900px) {
  .Table-module__list--ddqkL {
    table-layout: initial;
  }

  .Table-module__tableHead--3vToU,
  .Table-module__tableRow--3AB2t {
    display: table-row;
  }

  .Table-module__columnTitle--11-D9,
  .Table-module__tableCell--3jQ2f {
    padding: var(--pico);
    word-break: unset;
  }

  .Table-module__tableCell--3jQ2f,
  .Table-module__columnTitle--11-D9 {
    display: table-cell;
  }

  .Table-module__tableCell--3jQ2f {
    max-width: var(--max-table-cell-width);
    vertical-align: top;
  }

  .Table-module__columnTitle--11-D9 {
    white-space: nowrap;
  }

  .Table-module__columnTitle--11-D9:first-child,
  .Table-module__tableCell--3jQ2f:first-child {
    padding-left: 0;
  }

  .Table-module__itemTitle--foDhV {
  }

  .Table-module__isHighlighted--G_GTa {
  }
}

.BusinessDistrictSelection-module__formContainer--1Ixv9 {
  margin-top: 50px;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
}

.BusinessDistrictSelection-module__childContainer--2WONc {
  max-width: 584px;
}

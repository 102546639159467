.Login-module__container--3_u1M {
  display: flex;
  justify-content: space-around;
}

.Login-module__root--3sYMg {
  display: flex;
  flex-direction: column;
}

.Login-module__link--2ZTwj {
  display: inline-block;
  margin-bottom: var(--milli);
}

.Contract-module__signedContainer--17juc {
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.Contract-module__iframe--3FPLR {
  width: 100%;
  height: 400px;
  border: 1px solid grey;
  margin-top: 10px;
}

.Contract-module__downloadLink--3GorN {
  margin-left: 5px;
}

.Contract-module__downloadContractPreviewContainer--XJasU {
  padding-top: 5px;
}

.Contract-module__form--1ny68 {
  margin-top: 20px;
}

.Contract-module__agreeTocCheckbox--3T5eK {
  margin-left: 0px;
}

@media screen and (min-width: 768px) {
  .Contract-module__placeContainer--39bpv {
    width: 50%;
  }
}

.Contract-module__submit--2zg1N {
  text-align: center;
  margin-top: 10px;
}

.Contract-module__terms--1qe-b {
  font-size: 12px;
  display: block;
  max-width: 500px;
  margin: 10px 0;
  text-align: left;
}

.Contract-module__signedSection--11i7g {
  text-align: center;
}

.Contract-module__signedText--1hY9W {
  padding: 0 0 20px 0;
}

.Contract-module__icon--X_MUb {
  font-size: 36px;
}

.Contract-module__iconContainer--2ADow {
  margin: 20px;
}

.Tabs-module__root--3xMN0,
.Tabs-module__select--1vIdy {
  padding: var(--femto);
}

.Tabs-module__tab--JG36I {
  color: var(--text);
  display: inline-block;
  padding: var(--femto) var(--milli);
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .Tabs-module__root--3xMN0 {
    background: var(--pure-white);
    border: 0;
    border-bottom: var(--border);
    padding: 0;
  }
  .Tabs-module__isActive--3LeFZ {
    border-bottom: var(--border);
    border-bottom-color: var(--outline-tab-light-selected);
    font-weight: var(--font-weight-highlight);
  }
}

.TrainingCourses-module__chfTrainingsContainer--3Wnb- {
  margin-top: 30px;
  padding: 20px;
  border-top: solid var(--border-size) var(--color-border);
}
.TrainingCourses-module__coursesList--3L39V {
  max-width: 600px;
  margin: 20px auto;
}

.TrainingCourses-module__courseLink--2n_8c {
  text-decoration: none;
  color: var(--orange);
}

.TrainingCourses-module__progressDelay--cd1tq {
  margin-top: 20px;
  font-size: var(--body-small-size);
  font-weight: var(--font-weight);
  text-align: left;
}

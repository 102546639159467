.FlashMessage-module__root--1bt_n {
  color: var(--pure-white);
  line-height: var(--line-height);
  padding: var(--pico) 0;
  text-align: center;
}

.FlashMessage-module__failure--2xE2H {
  background-color: var(--color-error);
}

.FlashMessage-module__success--3vdNr {
  background-color: var(--color-validated);
}

.FlashMessage-module__alert--1fQeq {
  background-color: var(--color-warning);
}

.FlashMessage-module__link--2dbcR a {
  color: var(--pure-white);
  text-decoration: underline;
}

.Select-module__root--2r7wy {
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxNCA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgaWQ9InYwMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlJpZGVzX09mZmVyc18wMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMjEuMDAwMDAwLCAtOTc0LjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIxNi4wMDAwMDAsIDk2NS4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PC9yZWN0PgogICAgICAgICAgICAgICAgPGcgaWQ9Ikljb25zL0NoZXZyb24vRG93bi9MaW5lMS1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjAwMDAwMCwgOS4wMDAwMDApIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImNoZXZyb24tcmlnaHQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCAzLjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuMDAwMDAwLCAtMy4wMDAwMDApIHRyYW5zbGF0ZSgzLjAwMDAwMCwgLTMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgcG9pbnRzPSIwIDEyIDYgNiAwIDAiPjwvcG9seWxpbmU+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K) no-repeat right;
  background-origin: content-box;
  font-size: var(--body-size);
  line-height: var(--body-line);
  margin-bottom: var(--milli);
  padding: var(--femto) 0;
  outline-color: var(--outline-button-tertiary-hover);
  overflow: hidden;
}

.Select-module__inactive--3tLYm {
  color: var(--border-color);
  font-style: italic;
}

.Select-module__inputLabel--2PD1t {
  background-color: transparent;
  position: absolute;
}

.OnboardingButton-module__submitButton--WeXaY {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  background: var(--bg-button-primary);
  color: var(--text-button-primary);
}

.OnboardingButton-module__button--1qg7X {
  color: #ffffff;
}

.OnboardingButton-module__button--1qg7X.OnboardingButton-module__disabled--3rEQc {
  color: #ccc;
}

.OnboardingButton-module__submitButton--WeXaY:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.OnboardingButton-module__submitButton--WeXaY:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 0);
}

.OnboardingButton-module__icon--2fd7q {
  margin-right: 12px;
}

.Form-module__root--bGSDy {
  margin-top: var(--normal);
}

.Form-module__description--1wYyF {
  margin-top: var(--small);
}

@media screen and (min-width: 1024px) {
  .Form-module__multiColumnFormContainer--2qBCp {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: var(--milli);
  }
}

@media screen and (min-width: 900px) {
  .Form-module__fieldContainer--2MTT9 {
    box-sizing: border-box;
    width: 50% !important; /* Needed due to how the CSS Module's composes + design system works */
  }
}

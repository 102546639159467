.Permissions-module__permissionElement--3ikxJ {
  border-bottom: var(--border);
  padding: 12px 0;
}

.Permissions-module__permissionElement--3ikxJ:last-child {
  border-bottom: none;
}

.Permissions-module__permissionIcon--2nBM_ {
  vertical-align: middle;
  margin-right: var(--pico);
}

.Cookie-module__container--2P6Q- {
  background: var(--bg-text-boxes);
  bottom: 0;
  box-sizing: border-box;
  color: var(--text);
  padding: var(--deci);
  position: fixed;
  width: 100%;
  z-index: 2;
}

.Cookie-module__content--2qow4 {
  display: flex;
  margin: 0 auto;
  max-width: var(--max-page-width);
  text-align: justify;
}

.Cookie-module__content--2qow4 a {
  color: inherit;
}

.Cookie-module__dismissNotice--3OG0- {
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: var(--atto) var(--micro);
  width: auto;
}

.Cookie-module__dismissNoticeIcon--2ADHr {
  display: block;
}

@media screen and (min-width: 1024px) {
  .Cookie-module__content--2qow4 {
    align-items: flex-start;
  }

  .Cookie-module__policyText--gYzBd {
    margin-right: var(--deci);
  }

  .Cookie-module__dismissNotice--3OG0- {
    padding: 0;
  }
}

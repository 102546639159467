.Filters-module__root--X7hG5 {
  align-items: center;
  border-bottom: var(--border);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

button[data-type='tertiary'].Filters-module__toggleFilters--3JD_C {
  background-position: center right;
  background-repeat: no-repeat;
  margin: 0;
  padding-right: var(--micro);
}

.Filters-module__closeFilters--3pPU3 {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxNCA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgaWQ9InYwMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlJpZGVzX09mZmVyc18wMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyODguMDAwMDAwLCAtOTc0LjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyOTUuMDAwMDAwLCA5NzcuMDAwMDAwKSBzY2FsZSgxLCAtMSkgdHJhbnNsYXRlKC0xMjk1LjAwMDAwMCwgLTk3Ny4wMDAwMDApIHRyYW5zbGF0ZSgxMjgzLjAwMDAwMCwgOTY1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS1Db3B5IiB4PSIwIiB5PSIwIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiPjwvcmVjdD4KICAgICAgICAgICAgICAgIDxnIGlkPSJJY29ucy9DaGV2cm9uL0Rvd24vTGluZTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCA5LjAwMDAwMCkiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iY2hldnJvbi1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNi4wMDAwMDAsIDMuMDAwMDAwKSByb3RhdGUoLTI3MC4wMDAwMDApIHRyYW5zbGF0ZSgtNi4wMDAwMDAsIC0zLjAwMDAwMCkgdHJhbnNsYXRlKDMuMDAwMDAwLCAtMy4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoIiBwb2ludHM9IjAgMTIgNiA2IDAgMCI+PC9wb2x5bGluZT4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=);
}
.Filters-module__openFilters--ThN3l {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxNCA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgaWQ9InYwMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlJpZGVzX09mZmVyc18wMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMjEuMDAwMDAwLCAtOTc0LjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIxNi4wMDAwMDAsIDk2NS4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PC9yZWN0PgogICAgICAgICAgICAgICAgPGcgaWQ9Ikljb25zL0NoZXZyb24vRG93bi9MaW5lMS1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjAwMDAwMCwgOS4wMDAwMDApIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImNoZXZyb24tcmlnaHQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCAzLjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTYuMDAwMDAwLCAtMy4wMDAwMDApIHRyYW5zbGF0ZSgzLjAwMDAwMCwgLTMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgcG9pbnRzPSIwIDEyIDYgNiAwIDAiPjwvcG9seWxpbmU+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K);
}

.Filters-module__filterBox--32Azp {
  border-top: var(--border-size) solid var(--color-border);
  flex: 1 0 100%;
  margin-top: var(--small);
  padding-top: var(--small);
}
.Filters-module__filterActions--O7dsC {
  text-align: center;
}

.Filters-module__recordNumber--1xqyj {
  font-size: var(--body-small-size);
  margin: var(--pico) 0;
}

.Filters-module__recordNumber--1xqyj em {
  font-style: normal;
  font-weight: var(--font-weight-highlight);
}

@media screen and (min-width: 900px) {
  .Filters-module__filtersContainer--2HzWl {
    border: 0 none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }
  .Filters-module__filterActions--O7dsC {
    text-align: right;
  }
  .Filters-module__filterInputContainer--1_GSz {
    max-width: 25%;
  }
  .Filters-module__filterInputContainer--1_GSz + .Filters-module__filterInputContainer--1_GSz {
    margin-left: var(--pico);
  }
}

.Ratings-module__lspKpiList--1Ijt_ {
  border-bottom: solid var(--border-size) var(--color-border);
  display: flex;
  flex-wrap: wrap;
}

.Ratings-module__lspKpiItem--IS-IH {
  flex: 1;
}

.Ratings-module__lspKpiItem--IS-IH + .Ratings-module__lspKpiItem--IS-IH {
  margin-left: var(--micro);
}

.Ratings-module__averageCombinedRating--2e2vS {
  margin-right: var(--pico);
}

.Ratings-module__ratingsCount--YqOJP {
  align-self: flex-end;
  color: var(--text-hint);
  flex-grow: 1;
  font-size: var(--body-small-size);
  margin-bottom: var(--nano);
  text-align: right;
}

@media screen and (min-width: 1024px) {
  .Ratings-module__lspKpiItem--IS-IH {
    flex: 0 0 auto;
  }

  .Ratings-module__lspKpiItem--IS-IH + .Ratings-module__lspKpiItem--IS-IH {
    margin-left: var(--hecto);
  }
}

.Checkbox-module__checkbox--osWSe {
  display: block;
  font-size: var(--body-small-size);
  line-height: var(--body-line);
  letter-spacing: normal;
  margin: var(--zepto) 0 0 0;
  padding-bottom: var(--pico);
  text-transform: none;
  width: auto;
}

.Checkbox-module__errorLabel--24sXw {
  color: var(--color-error);
}

.Status-module__approved--2bvve::before,
.Status-module__deactivated--DeJnt::before,
.Status-module__disabled--3Ve9O::before,
.Status-module__draft--2YDlC::before,
.Status-module__expired--xbOrZ::before,
.Status-module__live--34eyP::before,
.Status-module__on_hold--2gqaq::before,
.Status-module__pending--35Icp::before,
.Status-module__rejected--3BxWO::before {
  border-radius: var(--pico);
  content: '';
  display: inline-block;
  height: 8px;
  margin-right: var(--nano);
  width: 8px;
}

.Status-module__on_hold--2gqaq::before,
.Status-module__pending--35Icp::before {
  background-color: var(--color-warning);
}

.Status-module__approved--2bvve::before,
.Status-module__live--34eyP::before {
  background-color: var(--color-validated);
}

.Status-module__rejected--3BxWO::before,
.Status-module__disabled--3Ve9O::before {
  background-color: var(--color-error);
}

.Status-module__deactivated--DeJnt::before,
.Status-module__draft--2YDlC::before,
.Status-module__expired--xbOrZ::before {
  background-color: var(--color-border);
}

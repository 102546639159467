.Empty-module__root--11B-d {
}

.Empty-module__image--3_bXa {
  display: block;
  margin: var(--micro) auto;
}

.Empty-module__text--2ntvb {
  display: block;
  text-align: center;
}

.Register-module__container--ie2Vs {
  display: flex;
  justify-content: space-around;
}

.Register-module__bdSelectionContainer--2RRSD {
  margin-top: 50px;
}

.Register-module__w584--Efan5 {
  width: 584px;
}

.Register-module__loginLink--20aaq {
  margin-bottom: 20px;
  text-align: right;
  margin-top: -30px;
}

.Register-module__successContainer--2VVui {
  max-width: 400px;
  text-align: center;
}

@media screen and (min-width: 900px) {
  .Register-module__row--3Jlid {
    display: flex;
    gap: 10px;
  }

  .Register-module__w150--3PLnZ {
    width: 150px;
    flex-shrink: 0;
  }
}

.Register-module__section--Rjh2f {
  margin-bottom: 20px;
}

.Register-module__consentHeadline--3zXHS {
  margin: 40px 0 10px 0;
}

.Register-module__icon--1ayRj {
  font-size: 48px;
}

.CertifiedScreen-module__buttonContainer--1LwYj {
  margin-top: 24px;
}

.CertifiedScreen-module__iconContainer--3V-Hg {
  margin: 20px;
}

.CertifiedScreen-module__icon--BwTPC {
  font-size: 36px;
}

.WelcomeScreen-module__iconContainer--XpmRJ {
  margin: 20px;
}

.WelcomeScreen-module__icon--3SAog {
  font-size: 36px;
}

.WelcomeScreen-module__buttonContainer--3LK25 {
  margin-top: 24px;
}

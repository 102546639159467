.BackToOverview-module__root--3J4Hl {
  background-origin: border-box;
  display: block;
  font-size: var(--body-small-size);
  line-height: var(--body-small-line);
  padding: var(--nano) 0;
  position: relative;
  text-decoration: none;
}

.BackToOverview-module__root--3J4Hl::after,
.BackToOverview-module__root--3J4Hl::before {
  background-origin: border-box;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
}

.BackToOverview-module__root--3J4Hl::before {
  left: -100%;
}

.BackToOverview-module__root--3J4Hl::after {
  right: -100%;
}

.BackToOverview-module__icon--2UO0x {
  margin-right: var(--pico);
}

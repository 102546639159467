/* Phone input globals */
.PhoneInput input,
.PhoneInput select,
.PhoneInput textarea,
.PhoneInput input:-webkit-autofill,
.PhoneInput input:-webkit-autofill:hover,
.PhoneInput input:-webkit-autofill:focus,
.PhoneInput input:-webkit-autofill:active {
  background: var(--color-input-background);
  box-shadow: 0 0 0 100vh var(--color-input-background) inset !important;
  box-sizing: border-box;
  font-size: var(--body-size);
  padding: var(--centi) calc(var(--atto) + var(--deka)) 10px 10px;
  width: 100%;
}

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-marginTop: calc(
    var(--PhoneInputCountrySelectArrow-height) / 2
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  display: flex;
  align-items: center;
  border: none !important;
  position: relative;
}

.PhoneInputInput {
  border: none;
  padding: var(--centi) calc(var(--atto) + var(--deka)) var(--femto) 10px;
}

.PhoneInputCountryIcon {
  height: auto;
  padding-top: calc(var(--atto) + 2px);
  width: var(--centi);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */
.PhoneInputCountry {
  align-items: center;
  align-self: stretch;
  border-right: 1px solid var(--polar-white);
  width: calc(var(--kilo) * 2);
  display: flex;
  position: relative;
  padding: var(--centi) calc(var(--pico) + 1px) var(--pico);
}

.PhoneInputCountrySelect {
  border-right: 1px solid var(--polar-white);
  border: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.PhoneInputCountrySelect[disabled] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-style: solid;
  border-top-width: 0;
  content: '';
  display: block;
  height: calc(var(--femto) - 1px);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
  position: absolute;
  right: calc(var(--pico) + 1px);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  width: 7.5px;
}

.PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.List-module__list--3-UP0 {
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 0;
  width: 100%;
}

.List-module__header--2lazh {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.List-module__filters--qTmL8 {
  border-width: 0 0 var(--border-size) 0;
}

.List-module__tableHead--3bI2D {
  display: none;
}

.List-module__tableRow--1n6Qf {
  border-bottom: solid var(--border-size) var(--color-border);
  display: block;
}

.List-module__tableCell--1Z-0G {
  word-break: break-word;
}

.List-module__itemTitle--38AmV {
  font-weight: var(--font-weight);
}

.List-module__columnTitle--21Y77,
.List-module__tableCell--1Z-0G {
  display: block;
  padding: var(--pico) 0;
}

.List-module__columnTitle--21Y77 {
  color: var(--text-hint);
  font-size: var(--body-small-size);
  font-weight: var(--font-weight);
  text-align: left;
}

.List-module__columnTitle--21Y77:last-child,
.List-module__tableCell--1Z-0G:last-child {
  padding-right: 0;
  text-align: right;
}

.List-module__listHead--1qapk {
  border-bottom: solid var(--border-size) var(--color-border);
}

.List-module__loadingMessage--Tron1 {
  margin-top: var(--pico);
}

@media screen and (min-width: 900px) {
  .List-module__list--3-UP0 {
    table-layout: initial;
  }

  .List-module__tableHead--3bI2D,
  .List-module__tableRow--1n6Qf {
    display: table-row;
  }

  .List-module__columnTitle--21Y77,
  .List-module__tableCell--1Z-0G {
    padding: var(--pico);
    word-break: unset;
  }

  .List-module__tableCell--1Z-0G,
  .List-module__columnTitle--21Y77 {
    display: table-cell;
  }

  .List-module__tableCell--1Z-0G {
    max-width: var(--max-table-cell-width);
    vertical-align: top;
  }

  .List-module__columnTitle--21Y77 {
    white-space: nowrap;
  }

  .List-module__columnTitle--21Y77:first-child,
  .List-module__tableCell--1Z-0G:first-child {
    padding-left: 0;
  }

  .List-module__itemTitle--38AmV {
  }

  .List-module__isHighlighted--2w5_B {
  }
}

:root {
  /* Grays */
  --pure-white: #fff;
  --disabled-input-background: #fbfbfd;
  --bg-input-error: #ffebea;
  --bg-text-boxes: #f5f5f5;
  --bg-disabled-input: #f0f1f3;
  --color-selected-item: #e3e5ea;
  --color-input-background: #f0f2f7;
  --line-divider: #caccd1;
  --text-hint: #97999e;
  --line-bottom: var(--cool-grey);
  --color-disabled-link-text: #7e8085;
  --color-input-text: #64666b;
  --color-divider: #303030;
  --main-black: #181a1f;
  --color-black: #000;
  --polar-white: #fbfbfd;
  --color-info: #fff3b8;
  --polar-white: #fbfbfd;
  --shadow-grey: #caccd1;
  --greyscale-900: #181a1f;
  --greyscale-300: #b0b2b7;

  /* Yellow */
  --rating: #ff9800;

  /* Orange */
  --orange: #ff4c00;
  --dark-orange: #cc434c;

  /* Reds */
  --color-error: #c61336;
  --icon-as-illustration: #ff5460;

  /* Greens */
  --green: #00a699;

  /* Gradients */
  --gradient-primary: linear-gradient(
    330.24deg,
    #ff6c00 11.99%,
    #e52c43 88.79%
  );
  --gradient-primary-active: linear-gradient(
    330.24deg,
    #f44600 11.99%,
    #c12700 88.79%
  );
  --gradient-primary-disabled: linear-gradient(
    330.24deg,
    #ff6c00 11.99%,
    #e52c43 88.79%
  );
  --vibrant-box: linear-gradient(
    to right,
    rgba(227, 15, 89, 0.8),
    rgba(255, 140, 36, 0.8)
  );
}

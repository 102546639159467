/* Typography: basic rules */
html,
body,
button,
input,
select,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: var(--font);
}

body {
  font-size: var(--body-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight);
  letter-spacing: 0.22px;
  line-height: var(--body-line);
}

/* Typography: heading containers */
header {
  box-shadow: var(--outline-header);
}

nav {
  background-color: var(--bg-header-primary);
}

nav li,
aside[role='menubar'] li {
  border-bottom: var(--yocto) solid var(--color-border);
  padding: 0 var(--micro);
}

nav a {
  color: var(--text-header-primary);
  display: inline-block;
  font-size: var(--subtitle-size);
  font-weight: var(--font-weight-highlight);
  line-height: var(--subtitle-line);
  padding: var(--nano) 0;
}

aside[role='menubar'] {
  background-color: var(--bg-header-secondary);
}

aside[role='menubar'] a {
  color: var(--text-header-secondary);
  cursor: pointer;
  display: inline-block;
  font-size: var(--body-size);
  line-height: var(--body-line);
  padding: var(--femto) 0;
}

@media (min-width: 1024px) {
  nav li,
  aside[role='menubar'] li {
    border-bottom: 0 none;
    padding: 0;
  }

  nav a {
    border-bottom: var(--zepto) solid transparent;
    margin-left: var(--hecto);
  }

  aside[role='menubar'] a {
    margin-left: var(--hecto);
  }
}

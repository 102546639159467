.TaskItem-module__taskItem--2BbuC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.TaskItem-module__enabled--3F9fA {
  transition: background-color 0.3s;
}

.TaskItem-module__enabled--3F9fA:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.TaskItem-module__disabled--jutjL:hover {
  cursor: not-allowed;
}
.TaskItem-module__disabled--jutjL {
  color: #caccd1;
}

.TaskItem-module__taskContent--_B8eL {
  display: flex;
  align-items: center;
}

.TaskItem-module__taskTitle--21lha {
  font-size: 14px;
}

.TaskItem-module__statusIcon--AVr3V {
  margin-right: 12px;
}
.TaskItem-module__statusLabel--uYhPr {
  font-size: 12px;
  color: #888;
}

.TaskItem-module__done--2RgKq {
  color: #008489;
}
.TaskItem-module__partial--2WHey {
  color: #64666b;
}

.TaskItem-module__rejected--1NyOs {
  color: #c61436;
}

.TaskItem-module__pending--3gVOJ {
  color: #caccd1;
}

.TaskItem-module__chevronIcon--dSG-b {
  font-size: 16px;
}

.ApplicantTypeSelection-module__boxes--27Bcm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
  margin: 50px 0;
}

.ApplicantTypeSelection-module__box--2Md-I {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.ApplicantTypeSelection-module__content--1FfAm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.ApplicantTypeSelection-module__icon--1x9i9 {
  margin-bottom: 20px;
}

.ApplicantTypeSelection-module__selected--3XeIH {
  border-color: black;
}

@media screen and (min-width: 480px) {
  .ApplicantTypeSelection-module__boxes--27Bcm {
    flex-direction: row;
  }
}

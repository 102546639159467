.Header-module__scrollBlock--3iIra {
  overflow: hidden;
}

.Header-module__primaryNavigationMobile--2Tx3A,
.Header-module__secondaryNavigationMobile--37oH6 {
  padding: var(--micro) 0;
}

.Header-module__primaryNavigation--3YZ_j a,
.Header-module__secondaryNavigation--iDF8Z a {
  text-decoration: none;
}

.Header-module__primaryNavigationMobile--2Tx3A {
  border-top: var(--border-size) solid var(--color-border);
}

.Header-module__navigationWrapperMobile--2nghZ {
  display: flex;
  padding: 0 var(--micro);
  position: relative;
}

.Header-module__logoContainer--1YJbt {
  display: flex;
}

.Header-module__menuMobile--1mJXc {
  background-color: var(--pure-white);
  border-left: var(--border-size) solid var(--color-border);
  display: none;
  height: calc(100vh - 50px);
  left: 0;
  margin: 0 0 0 10%;
  position: absolute;
  top: calc(var(--nano) * 5);
  width: 90%;
  z-index: 1;
}

.Header-module__isOpen--EfYpB {
  display: block;
  overflow: overlay;
}

.Header-module__menuMobileIcon--2auMt {
  font-size: var(--font-medium);
  margin: auto;
  padding-left: var(--normal);
}

.Header-module__menuMobileIcon--2auMt::after {
  content: '\2630';
}

.Header-module__menuMobileIconClose--UVYCD::after {
  content: '\2715';
}

.Header-module__link--2Oeqp {
  display: flex;
  flex-direction: column;
  list-style: none;
}

@media screen and (min-width: 1024px) {
  .Header-module__primaryNavigation--3YZ_j,
  .Header-module__secondaryNavigation--iDF8Z {
    align-items: center;
    display: flex;
  }

  .Header-module__secondaryNavigation--iDF8Z {
    justify-content: flex-end;
  }

  .Header-module__logoContainer--1YJbt {
    flex: 1;
  }

  .Header-module__link--2Oeqp {
    flex-direction: row;
  }
  .Header-module__isActive--OuOxP {
    border-bottom-color: var(--outline-header-selected);
  }
}

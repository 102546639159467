/* Fonts */
@font-face {
  font-display: swap;
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/fonts/AvenirNextLTPro-Regular-12bc53a036712506e157f25eb88fd957.woff2) format('woff2'),
    url(/_next/static/fonts/AvenirNextLTPro-Regular-d074ce088ea67a38314516d77892a66d.woff) format('woff'),
    url('/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('/fonts/AvenirNextLTPro-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'AvenirNextDemiBold';
  font-style: normal;
  font-weight: 600;
  src: url(/_next/static/fonts/AvenirNextLTPro-Demi-217f817ed9f396a57ed69f74e586b44b.woff2) format('woff2'),
    url(/_next/static/fonts/AvenirNextLTPro-Demi-f8264ade1746dde1c344b9a33e4e5f38.woff) format('woff'),
    url('/fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
    url('/fonts/AvenirNextLTPro-Demi.woff') format('woff');
}

/* All the :root selectors are not valid css thus they are here */

:root {
  --image-mobile-height: 580px;
  --image-small-desktop-height: 500px;
  --image-large-desktop-height: 480px;
  --signature-mobile-height: 55px;
  --story-box-width: 500px;
  --chauffer-title-font-size: 10px;
  --card-image-icon-width-height: 144px;
}

/* Typography */
:root {
  --font: 'AvenirNext', Helvetica, Arial, Open Sans, sans-serif;
  --font-demi: 'AvenirNextDemiBold', Helvetica, Arial, Open Sans, sans-serif;
  --font-weight: 400;
  --font-weight-highlight: 700;
  --body-small-size: 14px;
  --body-small-line: 20px;
  --body-size: 16px;
  --body-large-size: 18px;
  --body-line: 24px;
  --body-large-line: 28px;
  --label-size: 10px;
  --label-line: 20px;
  --subtitle-size: 20px;
  --subtitle-line: 28px;
  --heading3-size: 30px;
  --heading3-line: 42px;
  --heading4-size: 24px;
  --heading4-line: 32px;
}

/* Variables */
:root {
  /* Theme-specific colors */
  --pure-black: #000000;
  --main-black: #181a1f;
  --light-grey: #f5f5f5;
  --orange: #ff4c00;
  --dark-orange: #cc434c;
  --gradient-primary: linear-gradient(
    330.24deg,
    #ff6c00 11.99%,
    #e52c43 88.79%
  );
  --gradient-primary-active: linear-gradient(
    330.24deg,
    #f44600 11.99%,
    #c12700 88.79%
  );
  --gradient-primary-disabled: linear-gradient(
    330.24deg,
    #ff6c00 11.99%,
    #e52c43 88.79%
  );
  --vibrant-box: linear-gradient(
    to right,
    rgba(227, 15, 89, 0.8),
    rgba(255, 140, 36, 0.8)
  );

  /* Shared colors */
  --bg-input-error: #ffebea; /* background input with errors */
  --bg-quotes: #f0f2f7; /* background-quotes */
  --bg-text-boxes: var(--light-grey); /* background-text-boxes */
  --bg-disabled-input: #f0f1f3;
  --color-black: #000;
  --cool-grey: #b0b2b7;
  --color-divider: #303030;
  --color-border: var(--color-selected-item);
  --color-error: #c61336;
  --color-selected-item: #e3e5ea; /* selected-item */
  --success-bg: #afcfc7;
  --color-validated: #00a699;
  --color-warning: #ff9800;
  --color-input-text: #64666b;
  --color-disabled-input-text: #97999e;
  --color-disabled-input-label: #64666b;
  --color-disabled-link-text: #7e8085;
  --icon-as-illustration: #ff5460;
  --icon-fill: var(--text-hint);
  --icon-stroke: var(--text-hint);
  --line-bottom: var(--cool-grey); /* bottom-line */
  --line-divider: #caccd1; /* divider-line */
  --pure-white: #fff;
  --rating: #ff9800;
  --text-error: var(--color-error);
  --text-hint: #97999e; /* hint-text */
  --text-link: var(--color-black);
  --text: var(--color-black);
  --color-input-background: #f0f2f7;
  --color-highlighted-border: #97999e;
  --disabled-input-background: #fbfbfd;
  --bg-text-block: var(--light-grey);
  --black-jet: #313338;

  /* Borders */
  --border-size: 1px;
  --border-color: var(--color-selected-item);
  --border: var(--border-size) solid var(--border-color);
  --border-error: var(--border-size) solid var(--icon-as-illustration);
  --border-focus: 1px solid var(--outline-button-secondary-hover);

  /* Buttons */
  --bg-button-primary-active: var(--gradient-primary-active);
  --bg-button-primary-hover: var(--gradient-primary-active);
  --bg-button-primary-disabled: var(--gradient-primary-disabled);
  --bg-button-primary: var(--gradient-primary);
  --bg-button-secondary-active: var(--pure-white);
  --bg-button-secondary-disabled: var(--pure-white);
  --bg-button-secondary: var(--pure-white);
  --bg-button-tertiary-active: transparent;
  --bg-button-tertiary-hover: transparent;
  --bg-button-tertiary: transparent;
  --outline-button-secondary: var(--orange);
  --outline-button-secondary-hover: var(--orange);
  --outline-button-secondary-active: var(--dark-orange);
  --outline-button-tertiary-hover: rgba(248, 90, 18, 0.6);
  --text-button-primary-active: #fff;
  --text-button-primary-hover: #fff;
  --text-button-primary: #fff;
  --text-button-primary-disabled: var(--pure-white);
  --text-button-primary-active: var(--pure-white);
  --text-button-secondary-active: var(--dark-orange);
  --text-button-secondary-disabled: var(--orange);
  --text-button-secondary-hover: var(--orange);
  --text-button-secondary: var(--orange);
  --text-button-tertiary-active: var(--main-black);
  --text-button-tertiary-hover: var(--pure-black);
  --text-button-tertiary: var(--pure-black);

  /* Colors: tab */
  --outline-tab: #424242;
  --outline-tab-light: var(--color-selected-item);
  --outline-tab-light-selected: var(--text);
  --outline-tab-width: 1px;
  --bg-tab-active: #292929;
  --bg-tab-inactive: #0d0d0d;
  --text-tab-active: var(--pure-white);
  --text-tab-inactive: var(--text-hint);

  /* Container */
  --bg-container-start: #292929;
  --bg-container-end: #181a1f;
  --bg-container: linear-gradient(
    160deg,
    var(--bg-container-start),
    var(--bg-container-end)
  );
  --max-page-width: 1170px;
  --max-table-cell-width: 260px;
  --max-textonly-page-width: 860px;
  --item-box-shadow: 0px var(--femto) var(--nano) #caccd1;

  /* Header */
  --bg-header-secondary: var(--bg-text-boxes);
  --bg-header-primary: var(--pure-white);
  --text-header-secondary: var(--text);
  --text-header-primary: var(--text);
  --outline-header-selected: var(--text);
  --outline-header: 0 2px 10px 0 rgba(0, 0, 0, 0.05),
    0 -1px 10px 0 rgba(0, 0, 0, 0.01);

  /* Table */
  --table-row-border-bottom-color: var(--border-color);
  --table-row-item-arrow-icon-height: 10px;
  --table-row-item-arrow-icon-margin-right-left: 10px;
  --table-row-item-arrow-icon-width: 10px;

  /* Tab */
  --tab-gutter: 5px;

  /* Footer */
  --text-footer: var(--pure-white);
  --text-footer-link: var(--line-bottom);

  /* Spacings */
  --yocto: 1px;
  --zepto: 2px;
  --atto: 4px;
  --femto: 8px;
  --pico: 12px;
  --nano: 16px;
  --micro: 20px;
  --milli: 24px;
  --centi: 28px;
  --deci: 32px;
  --deka: 40px;
  --hecto: 48px;
  --kilo: 64px;
  --mega: 96px;
  --giga: 133px;
  --ultra: 172px;
  --tera: 180px;
  --formFieldPadding: var(--micro) var(--femto) var(--femto) var(--femto);

  /* Letter Spacings */
  --letter-spacing-small: 0.15px;
  --letter-spacing-medium: 0.22px;
}

.ApprovedScreen-module__iconContainer--GJDKH {
  margin: 20px;
}

.ApprovedScreen-module__icon--2u1TK {
  font-size: 36px;
}

.ApprovedScreen-module__contactEmailContainer--2VoCu {
  margin-top: 20px;
}

.ApprovedScreen-module__buttonContainer--1rD1g {
  margin-top: 24px;
}

.ApprovedScreen-module__iframe--3kW1d {
  width: 100%;
  height: 1000px;
  border: 0;
  background-color: transparent;
}

.ApprovedScreen-module__iframeContainer--1gTvh {
  align-items: center;
  text-align: center;
  margin: 50px auto 0;
  max-width: 1000px;
  display: grid;
  gap: 12px;
}

.ApprovedScreen-module__warningBox--2RIea {
  background-color: #fff3b8;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
}

.ApprovedScreen-module__warningBoxText--3c7cy {
  text-align: left;
}

.ApprovedScreen-module__warningBoxIcon--1r4Qy {
  font-size: 20px;
}

.ApprovedScreen-module__warningBoxButton--3KFeH {
  padding: 0;
  margin: 0;
  white-space: nowrap;
  background: transparent;
  font-weight: bold;
  font-size: 14px;
}

.ApprovedScreen-module__customToasts--3F7dD {
  background: #e3e5ea !important;
  width: 100%;
  font-weight: bold;
}

.ApprovedScreen-module__customToasts--3F7dD div:last-child {
  justify-content: left;
}

@media screen and (min-width: 1000px) {
  .ApprovedScreen-module__iframe--3kW1d {
    width: 1000px;
  }
}
